
<template>
    <div class="card">
        <Toast />
        <div class="flex">
            <div class="m-2">
                <div class="p-float-label">
                    <Dropdown v-model="selectedSemester" @change="filterData" inputId="slc-semester" :options="semesters"
                        optionLabel="semester" class="w-full md:w-14rem" />
                    <label for="slc-semester"><i class="pi pi-calendar" style="font-weight: 900;"></i> Buscar
                        período</label>
                </div>
            </div>
            <div class="m-2">
                <button @click="reloadData" title="Recarregar" severity="secondary"
                    class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
                    <i class="pi pi-refresh" style="font-weight: 900;"></i>
                </button>
            </div>
            <div class="m-2">
                <button @click="visibleRight = true" title="Infos" severity="secondary"
                    class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
                    <i class="pi pi-chart-bar" style="font-weight: 900;"></i>
                </button>
            </div>
            <div class="m-2">
                <button @click="openUserManagementModal" title="Cadastrar" severity="secondary"
                    class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
                    <i class="pi pi-user-plus" style="font-weight: 900;"></i>
                </button>
            </div>
            <div class="m-2">
                <button @click="openEmailModal" title="Email" severity="secondary"
                    class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
                    <i class="pi pi-envelope" style="font-weight: 900;"></i>
                </button>
            </div>
        </div>
        <div style="margin-top: 2%;">
            <div v-if="responseData !== undefined">

                <DataTable :value="responseData" tableStyle="min-width: 50rem" expandableRowGroups
                    v-model:expandedRowGroups="expandable_1" rowGroupMode="subheader" groupRowsBy="area" sortMode="single"
                    sortField="area" :sortOrder="1">
                    <template #groupheader="slotProps">
                        <span class="vertical-align-middle ml-2 font-bold line-height-3">{{
                            slotProps.data.area }}</span>
                    </template>
                    <Column>
                        <template #body="slotProps">
                            <Timeline :value="slotProps.data.coverage" layout="horizontal"
                                class="timelineCustom w-full md:w-50rem" align="alternate">
                                <template #marker="slotProps">
                                    <div class="p-timeline-event-marker"
                                        v-tooltip.top="{ value: getTooltipSeverity(slotProps.item), class: 'text-center' }"
                                        :style="{ backgroundColor: getTimelineSeverity(slotProps.item), border: '0px' }">
                                    </div>
                                </template>

                                <template #content="slotProps">
                                    {{ slotProps.index }}
                                </template>
                            </Timeline>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <Sidebar v-model:visible="visibleRight" position="right">
        <div style="margin-top: 20%;">
            <Card class="card text-center" style="padding: 0px; border: 1px solid green;">
                <template #title>
                    Envios
                </template>
                <template #content>
                    <h5>15</h5>
                </template>
            </Card>
            <Card class="card text-center" style="padding: 0px; border: 1px solid yellow;">
                <template #title>
                    Envios em atraso
                </template>
                <template #content>
                    <h5>15</h5>
                </template>
            </Card>
            <Card class="card text-center" style="padding: 0px; border: 1px solid red;">
                <template #title>
                    Envios pendentes
                </template>
                <template #content>
                    <h5>15</h5>
                </template>
            </Card>
        </div>
    </Sidebar>

    <!-- Modal de cadastrar usuário -->
    <Dialog class="userManagementDialog" :showHeader="false" :dismissable-mask="true" v-model:visible="modalUserManagement"
        modal :contentStyle="{ borderRadius: '5px', minWidth: '60rem' }">
        <Splitter class="userManagementSplitter" style="height: 450px">
            <SplitterPanel :size="65" :minSize="65">
                <Card style="height:100%; border-radius: 6px">
                    <template #title>
                        <div class="text-center">
                            <img src="images/userPng.png" style="width: 60px; height:60px">
                        </div>
                    </template>
                    <template #content>
                        <div class="ml-2">
                            <div class="flex mt-4">
                                <div class="p-float-label">
                                    <Dropdown style="border: 1px solid grey" v-model="newUserArea" @change="filterData"
                                        inputId="slc-semester" :options="registeredAreas" optionLabel="name"
                                        class="w-full md:w-16rem" />
                                    <label for="slc-semester"><i class="pi pi-map-marker" style="font-weight: 900;"></i>
                                        Área</label>
                                </div>
                                <div class="p-float-label ml-3">
                                    <AutoComplete class="customAutoComplete" @click="clearNewUserFields" v-model="newUser"
                                        style="border: 1px solid grey; border-radius: 6px;" :suggestions="suggestedUsers"
                                        field="mail" @complete="searchUser" @item-select="returnManager"
                                        :forceSelection="true" />
                                    <label for="newUser">
                                        <i class="pi pi-user" style="font-weight: 900;">
                                        </i> Novo usuário
                                    </label>
                                </div>
                            </div>
                            <div class="mt-6">
                                <div class="flex">
                                    <div class="p-float-label w-full md:w-16rem">
                                        <InputText type="text" style="border: 1px solid grey" v-model="newUserHeadName"
                                            disabled />
                                        <label for="newUserHeadName">
                                            <i class="pi pi-user" style="font-weight: 900;">
                                            </i> Gestor
                                        </label>
                                    </div>
                                    <div class="p-float-label w-full md:w-18rem ml-3">
                                        <InputText type="text" style="border: 1px solid grey" v-model="newUserHeadEmail"
                                            class="w-full md:w-18rem" disabled />
                                        <label for="newUserHeadEmail">
                                            <i class="pi pi-envelope" style="font-weight: 900;">
                                            </i> Email
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center" style="margin-top:60px">
                            <Button @click="registerNewUser" :disabled="checkNewUserValidity()" class="p-button p-component"
                                label="Cadastrar" />
                        </div>
                    </template>
                </Card>
            </SplitterPanel>
            <SplitterPanel :size="35" :minSize="35">
                <Fieldset class="userManagementFieldset m-1" style="height:100%">
                    <template #legend>
                        <div class="flex align-items-center">
                            <span class="pi pi-user mr-2"></span>
                            <span class="font-bold">Usuários cadastrados</span>
                        </div>
                    </template>
                    <div>
                        <DataTable class="userManagementTable" scrollable scrollHeight="305px" v-model:filters="filters"
                            :value="registeredUsers" filter tableStyle="width: 19rem" expandableRowGroups
                            v-model:expandedRowGroups="expandable_2" rowGroupMode="subheader" groupRowsBy="area"
                            :globalFilterFields="['mail']" sortMode="single" sortField="area" :sortOrder="1">
                            <template #header>
                                <div class="flex justify-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value"
                                            @input="expandOnFilter(filters['global'].value)" placeholder="Buscar Usuário" />
                                    </span>
                                </div>
                            </template>
                            <template #groupheader="slotProps">
                                <span class="vertical-align-middle ml-2 font-bold line-height-3">
                                    {{ slotProps.data.area }}
                                </span>
                            </template>
                            <Column>
                                <template #body="slotProps">
                                    <div style="width: 170px; white-space:nowrap">
                                        {{ slotProps.data.mail.replace("@btgpactual.com", "") }}
                                    </div>
                                </template>
                            </Column>
                            <Column>
                                <template #body="slotProps">
                                    <div class="text-right">
                                        <Button @click="deleteUser(slotProps.data.mail)"
                                            class="deleteUserButton p-button p-component p-button-icon-only p-button-danger p-button-rounded p-button-text"
                                            title="Excluir usuário" icon="pi pi-times" />
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </Fieldset>
            </SplitterPanel>
        </Splitter>
    </Dialog>

    <!-- Modal de fluxo de emails -->
    <Dialog class="emailDialog" :showHeader="false" :dismissable-mask="false" @show="initQuill()"
        v-model:visible="modalEmail" modal :contentStyle="{ borderRadius: '5px', minWidth: '65rem' }">
        <Splitter class="emailSplitter" style="height: 500px">
            <SplitterPanel :size="65" :minSize="65">
                <Card style="height:100%; border-radius: 6px">
                    <template #title>
                        <div class="flex">
                            <div style="width:56%;" class="text-right">
                                <img src="images/emailPng.png" style="width: 60px; height:60px">

                            </div>
                            <div style="width:44%;" class="text-right">
                                <Button style="color: grey" @click="modalEmail = false"
                                    class="p-button p-component p-button-icon-only p-button-rounded p-button-text"
                                    icon="pi pi-times" />
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="text-center">

                            <div class="customEmail m-1">

                                <div class="flex">
                                    <div style="width:50%" class="p-float-label">
                                        <Dropdown v-model="selectedTemplate" :options="templateOptions"
                                            @change="selectEmailTemplate"
                                            style="border-radius: 8px 0px 0px 0px; width:100%" />
                                        <label for="subject"><i class="pi pi-star" style="font-weight: 900;"></i>
                                            Templates</label>
                                    </div>

                                    <div style="width:50%" class="p-float-label">
                                        <InputText style="border-radius: 0px 8px 0px 0px; width:100%" v-model="subject" />
                                        <label for="subject"><i class="pi pi-book" style="font-weight: 900;"></i>
                                            Assunto</label>
                                    </div>
                                </div>

                                <div class="bodyEmail" id="editor-container">
                                    <p></p>
                                </div>
                                <br />
                            </div>

                            <div class="text-left ml-2">
                                <Button @click="sendMail" class="p-button p-component p-button-rounded" icon="pi pi-send"
                                    label="Enviar" />
                            </div>
                        </div>
                    </template>
                </Card>
            </SplitterPanel>
            <SplitterPanel :size="40" :minSize="40">
                <Fieldset class="emailFieldset m-1" style="height:100%">
                    <template #legend>
                        <div class="flex align-items-center">
                            <span class="pi pi-user mr-2"></span>
                            <span class="font-bold">Usuários cadastrados</span>
                        </div>
                    </template>
                    <div>
                        <Tree scrollable scrollHeight="340px" style="height: 100%"
                            v-model:selectionKeys="selectedToEmailFromTree" :value="listToEmailTree"
                            selectionMode="checkbox" :filter="true" filterMode="lenient"></Tree>
                    </div>
                </Fieldset>
            </SplitterPanel>
        </Splitter>
    </Dialog>
</template>

<script>
import Util from "../service/Util";
import DROService from "../service/DROService";
import LaraService from "../service/LaraService";
import { FilterMatchMode } from 'primevue/api';
import Quill from "quill";
import QuillBetterTable from "quill-better-table";

export default {
    data() {
        return {
            filters: { global: { value: null, matchMode: FilterMatchMode.CONTAINS } },
            templateOptions: ['Atrasados', "Em dia"],
            selectedTemplate: null,
            quill: null,
            newUser: null,
            email: "",
            subject: null,
            selectedToEmailFromTree: null,
            registeredAreas: [],
            newUserArea: null,
            listToEmailTree: [],
            areasWithUsers: [],
            suggestedUsers: [],
            registeredUsers: [],
            newUserHeadEmail: null,
            newUserHeadName: null,
            modalUserManagement: false,
            modalEmail: false,
            selectedSemester: null,
            responseData: [],
            expandable_1: null,
            expandable_2: null,
            visibleRight: false,
            currentSemester: null,
            semesters: [],
        };
    },
    mounted() {
        this.currentSemester = this.getCurrentSemester()
        this.semesters = this.getSemesters()
        this.reloadData()
        Quill.register(
            {
                "modules/better-table": QuillBetterTable,
            },
            true
        );
    },
    watch: {
        selectedToEmailFromTree() {
            this.returnSelectedUsersToMail()
        }
    },
    methods: {
        // Início fluxo de emails
        selectEmailTemplate(event) {
            if (event.value == "Atrasados") {
                this.subject = "Assunto Atrasados"
                this.quill.root.innerHTML = "\
                <h2>Corpo email atrasados</h2>\
                <p>Este é um paragrafo</p>\
                 "
            }
        },
        sendMail() {
            Util.loading = true
            let to = this.returnSelectedUsersToMail()
            this.email = this.quill.root.innerHTML;

            DROService.sendMail(this.subject, to, this.email).then((response) => {

                if (response.success) {
                    this.subject = null
                    this.quill.root.innerHTML = ""
                    this.selectedToEmailFromTree = {}

                    this.$toast.add({
                        severity: "success",
                        summary: "Email enviado com sucesso!",
                        life: 10000,
                    });
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Erro ao enviar email!",
                        life: 10000,
                    });
                }
                Util.loading = false
            })
        },
        initQuill() {
            this.quill = new Quill("#editor-container", {
                placeholder: "Escreva o email",
                theme: "snow", // or 'bubble'
                modules: {
                    table: false, // disable table module
                    "better-table": {
                        operationMenu: {
                            items: {
                                unmergeCells: {
                                    text: "Another unmerge cells name",
                                },
                            },
                        },
                    },
                    keyboard: {
                        bindings: QuillBetterTable.keyboardBindings,
                    },
                },
            });
        },
        parseStatusArea(status) {
            if (status == 'approved') {
                return "Aprovado"
            }

            if (status == 'refused') {
                return "Recusado"
            }

            if (status == 'pending approval') {
                return "Pendente"
            }

            if (status == 'late') {
                return "Atrasado"
            }
        },
        openEmailModal() {
            Util.loading = true
            this.subject = null
            this.selectedTemplate = null
            this.selectedToEmailFromTree = {}

            let tempArrayStatus = []
            let tempArrayStruct = []
            let tempChildrenArray = []

            DROService.listAreasStatus().then((response) => {
                response.forEach((area) => {
                    if (tempArrayStatus.includes(area.status) == false) {
                        tempArrayStatus.push(area.status)
                    }
                })

                tempArrayStatus.forEach((status) => {
                    tempArrayStruct.push({
                        key: status,
                        label: status,
                        children: []
                    })
                })

                response.forEach((area) => {
                    tempArrayStruct.forEach((status) => {
                        if (area.status == status.label) {
                            status.children.push({
                                key: area.name,
                                icon: 'pi pi-map-marker',
                                label: area.name,
                                children: []
                            })

                            tempChildrenArray = []
                            area.users.forEach((user) => {
                                tempChildrenArray.push({
                                    key: user,
                                    icon: 'pi pi-user',
                                    label: user
                                })
                            })
                            status.children[status.children.length - 1].children = tempChildrenArray
                        }
                    })
                })

                tempArrayStruct.forEach((status) => {
                    status.label = this.parseStatusArea(status.label)
                })

                this.listToEmailTree = tempArrayStruct
                this.modalEmail = true
                Util.loading = false
            })
        },
        returnSelectedUsersToMail() {
            let tempArray = Object.entries(this.selectedToEmailFromTree).map(([key, value]) => ({ key, value }))
            let tempSelection = []

            tempArray.forEach((selection) => {
                if (selection.key.includes("@btgpactual.com")) {
                    tempSelection.push(selection.key)
                }
            })
            return tempSelection
        },
        //Inicio funcoes modal cadastrar
        expandOnFilter(filteredWord) {
            if (filteredWord != "") {
                this.expandable_2 = this.areasWithUsers
            }
            else {
                this.expandable_2 = null
            }
        },
        deleteUser(userEmail) {
            Util.loading = true

            DROService.deleteUser(userEmail).then((response) => {
                if (response.success) {
                    this.listRegisteredUsers()

                    this.$toast.add({
                        severity: "success",
                        summary: "Usuário deletado com sucesso!",
                        life: 10000,
                    });
                }
                else {
                    Util.loading = false

                    this.$toast.add({
                        severity: "error",
                        summary: "Erro ao deletar usuário!",
                        life: 10000,
                    });
                }
            })
        },
        checkNewUserValidity() {
            if (this.newUser == null || this.newUserArea == null || !this.newUser.id) {
                return true
            }
            return false
        },
        clearNewUserFields() {
            this.newUser = null
            this.newUserHeadName = null
            this.newUserHeadEmail = null
        },
        registerNewUser() {
            let userInfo = {
                mail: this.newUser.mail,
                area: parseInt(this.newUserArea.id)
            }

            Util.loading = true
            DROService.registerUser(userInfo).then((response) => {
                if (response.success) {
                    this.listRegisteredUsers()
                    this.clearNewUserFields()

                    this.$toast.add({
                        severity: "success",
                        summary: "Usuário inserido com sucesso!",
                        life: 10000,
                    });
                }
                else {
                    Util.loading = false
                    this.$toast.add({
                        severity: "error",
                        summary: "Erro ao inserir usuário!",
                        life: 10000,
                    });
                }

            })
        },
        openUserManagementModal() {
            Util.loading = true
            //Limpando as variáveis
            this.newUser = null
            this.newUserHeadName = null
            this.newUserHeadEmail = null
            this.newUserArea = null
            this.expandable_2 = null

            //chamando as requisições
            this.modalUserManagement = true
            this.listRegisteredUsers()
            this.listArea()
        },
        listArea() {
            let tempArray = []
            DROService.areaList().then((response) => {
                response.forEach((area) => {
                    tempArray.push({
                        name: area.name,
                        id: area.id
                    })
                }
                )
                this.registeredAreas = tempArray
                if (Util.loading && this.registeredUsers != []) {
                    Util.loading = false
                }
            })
        },
        returnManager(event) {
            Util.loading = true
            LaraService.returnUserManager(event.value.id).then((response) => {
                this.newUserHeadName = response.attributes.name
                this.newUserHeadEmail = response.attributes.userPrincipalName.toLowerCase()
                Util.loading = false
            })
        },
        listRegisteredUsers() {
            this.registeredUsers = []
            this.areasWithUsers = []

            let tempArray = []

            DROService.listRegisteredUsers().then((response) => {
                response.forEach((user) => {
                    if (tempArray.includes(user.area) == false) {
                        tempArray.push(user.area)
                    }
                })

                this.registeredUsers = response
                this.areasWithUsers = tempArray

                if (Util.loading && this.registeredAreas != []) {
                    Util.loading = false
                }
            })
        },
        searchUser(event) {
            let tempArray = []

            LaraService.searchUser(event.query).then((response) => {
                response.forEach((user) => {
                    if (user.attributes.mail != "") {
                        tempArray.push({
                            mail: user.attributes.mail.toLowerCase(),
                            id: user.objectGUID
                        })
                    }
                })
                this.suggestedUsers = tempArray
            })
        },
        //fim funçoes modal cadastrar
        reloadData() {
            this.selectedSemester = null
            this.responseData = []
            Util.loading = true;
            DROService.areaListTimeline(this.currentSemester.semester, this.currentSemester.year).then(data => {

                this.responseData = data
                Util.loading = false;
            })
        },
        getCurrentSemester() {
            let currentDate = new Date()
            if (parseInt(currentDate.getMonth()) <= 5) {
                return {
                    semester: 1,
                    year: parseInt(currentDate.getFullYear())
                }
            }
            else {
                return {
                    semester: 2,
                    year: parseInt(currentDate.getFullYear())
                }
            }

        },
        getSemesters() {

            const startingSemester = {
                semester: 1,
                year: 2022
            }

            // creating a clone because "=" references and not copy
            let tempSemester = { ...this.currentSemester }

            const semester = [];

            while (tempSemester.year >= startingSemester.year && tempSemester.semester >= startingSemester.semester) {

                semester.push({
                    semester: tempSemester.year + '.' + tempSemester.semester
                });
                tempSemester.semester--;
                if (tempSemester.semester == 0) {
                    tempSemester.semester = 2;
                    tempSemester.year--;
                }
            }
            return semester;
        },
        filterData() {
            if (this.selectedSemester == null) {
                return
            }

            Util.loading = true;
            const [year, semester] = this.selectedSemester.semester.split(".");

            this.responseData = []
            DROService.areaListTimeline(semester, year).then(data => {
                this.responseData = data
                Util.loading = false;
            })
        },
        getTimelineSeverity(coverageInfo) {
            if (coverageInfo.empty) {
                return 'black'
            }
            else {
                if (coverageInfo.hasData && coverageInfo.hasSended) {
                    return 'green'
                }
                else if (!coverageInfo.hasData && coverageInfo.hasSended) {
                    return 'grey'
                }
                else if (coverageInfo.hasData && !coverageInfo.hasSended) {
                    return 'yellow'
                }
                else if (!coverageInfo.hasData && !coverageInfo.hasSended) {
                    return 'red'
                }

            }
        },
        getTooltipSeverity(coverageInfo) {
            if (coverageInfo.empty) {
                return 'Período futuro ou não existente na base'
            }
            else {
                if (coverageInfo.hasData && coverageInfo.hasSended) {
                    return 'Foi enviado planilha e possuímos dados referentes ao mês'
                }
                else if (!coverageInfo.hasData && coverageInfo.hasSended) {
                    return 'Foi enviado planilha mas não possuímos dados referentes ao mês'
                }
                else if (coverageInfo.hasData && !coverageInfo.hasSended) {
                    return 'Não foi enviado planilha mas possuímos dados referentes ao mês'
                }
                else if (!coverageInfo.hasData && !coverageInfo.hasSended) {
                    return 'Não foi enviado planilha e não possuímos dados referentes ao mês'
                }

            }
        }
    }
};
</script>

<style>
.timelineCustom {
    white-space: nowrap
}

.customAutoComplete .p-autocomplete-input {
    width: 17.8rem;
}

.userManagementTable .p-datatable-tbody td {
    height: 35px;
}

.userManagementTable thead {
    display: none !important;
}

.userManagementFieldset .p-fieldset-content,
.emailFieldset .p-fieldset-content {
    padding: 0;
}

.userManagementFieldset .p-fieldset-legend,
.userManagementFieldset,
.emailFieldset .p-fieldset-legend,
.emailFieldset {
    border: 1px solid grey;
}

.userManagementFieldset,
.emailFieldset {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.userManagementSplitter .p-splitter-gutter,
.emailSplitter .p-splitter-gutter {
    display: none
}

.userManagementDialog .p-dialog-content,
.userManagementSplitter,
.emailDialog .p-dialog-content,
.emailSplitter {
    background-color: rgba(0, 0, 0, 0)
}

.userManagementDialog,
.emailDialog {
    box-shadow: none
}

.userManagementSplitter,
.emailSplitter {
    border: none
}

.customEmail .ql-toolbar {
    border-radius: 0px 0px 0px 0px;
}

.bodyEmail {
    border-radius: 0px 0px 8px 8px;
    height: 13rem;
}

.deleteUserButton {
    height: 20px !important;
    width: 20px !important;
    padding: 0px !important;
}
</style>